<template>
  <div class="student-task" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="我的作业"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <div class="sliding_box">
      <el-scrollbar>
        <ul>
          <li
            v-for="(item, index) in courseList"
            :key="index"
            @click="handleChange(item)"
            :class="item.CourseId == CourseId ? 'active' : ''"
          >
            {{ item.CourseName }}
          </li>
        </ul>
      </el-scrollbar>
    </div>
    <div :style="{ height: contentHeight }" v-if="feedList.length > 0">
      <van-list
        v-model="loading"
        :finished="isEnd"
        @load="init"
        finished-text="没有更多了"
      >
        <van-cell
          v-for="(item, index) in feedList"
          :key="index"
          @click="goDetail(item)"
        >
          <template #title>
            <span class="custom-title">{{ item.Title }}</span>
          </template>
          <template #label>
            <span class="custom-time"
              >截止日期：{{ item.FinishTime | dataYMD }}</span
            >
          </template>
          <template #extra>
            <span
              class="custom-right"
              :class="[
                item.StudentWorkStatus == 0
                  ? 'bgc_333333'
                  : item.StudentWorkStatus == 1
                  ? 'bgc_E2E2E2'
                  : 'bgc_F1C800',
              ]"
              >{{
                item.StudentWorkStatus == 0
                  ? "未批阅"
                  : item.StudentWorkStatus == 1
                  ? "已批阅"
                  : "未提交"
              }}</span
            >
          </template>
        </van-cell>
      </van-list>
    </div>
    <div class="cont" v-else>
      <no-data></no-data>
    </div>
  </div>
</template>
<script>
import {
  studentQueryCourseWorkList,
  questionBankQueryQuestionCourse,
} from "@/api/personal";

import noData from "@/components/noData";
export default {
  name: "index",
  components: {},
  provide() {
    return {
      finishDelete: "",
    };
  },
  watch: {
    isAdd: function (newValue) {
      // this.getContent();
      this.postQueryAllTopicList();
    },
    searchList: function (newValue) {
      this.feedList = newValue;
      console.log("更新列表");
    },
  },
  components: {
    noData,
  },
  data() {
    return {
      feedList: [],
      activeName: "",
      followList: [],
      loading: false,
      recPage: 1, //推荐页面page
      pageSize: 10,
      isEnd: false,
      user: {},
      showModal: false,
      disabled: true,
      contentHeight: 0,
      courseList: [],
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "我的作业";
      this.isWxApplets = false;
    }
  },
  mounted() {
    // console.log(localStorage.getItem("studentTaskObj"));

    // this.getContent();
    this.contentHeight = document.documentElement.clientHeight - 66 - 40 + "px";
    this.user.Id = this.$route.query.UserId > 0 ? this.$route.query.UserId : 0;
    console.log(this.$route.query, "");
    // this.init();
    // this.listenScroll(document.documentElement);
    this.getQuestionBankQueryQuestionCourse();
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      localStorage.removeItem("studentTaskObj");
      window.history.go(-1);
    },
    handleChange(obj) {
      // console.log(value);
      // this.CourseId = value.split(',')[0];
      // this.myCourseId = value.split(',')[0];
      // this.courseActive = value.split(',')[1];
      this.CourseId = obj.CourseId;
      this.recPage = 1;
      this.feedList = [];
      this.init();
    },

    async getQuestionBankQueryQuestionCourse() {
      let parm = {
        studentId: this.user.Id,
      };
      const res = await questionBankQueryQuestionCourse(parm);
      if (res.success === true) {
        this.courseList = res.response;
        this.CourseId = this.courseList[0].CourseId;
        // this.$router.push({ path: this.$route.path, query: {CourseId:this.CourseId}});
        // 返回时缓存选择的课程
        if (localStorage.getItem("studentTaskObj")) {
          let obj = JSON.parse(localStorage.getItem("studentTaskObj"));
          this.handleChange(obj);
        } else {
          this.init();
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    async init() {
      let parm = {
        courseId: this.CourseId,
        pageIndex: this.recPage,
        pageSize: this.pageSize,
        studentId: this.user.Id,
      };
      const res = await studentQueryCourseWorkList(parm);
      if (res.success === true) {
        // this.feedList = this.feedList.concat(res.response.data);
        for (let i = 0; i < res.response.data.length; i++) {
          this.feedList.push(res.response.data[i]);
        }
        this.loading = false;
        this.recPage++;
        if (res.response.dataCount <= this.feedList.length) {
          this.isEnd = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    showAskModal() {
      if (this.user.Id == 0) {
        this.$message.error("请先登录");
        return false;
      }
      this.$router.push({
        path: "/h5/bbs/edit",
        query: {
          sectionList: JSON.stringify(this.sectionList),
          userId: this.user.Id,
        },
      });
    },
    goDetail(obj) {
      obj.StudentId = this.user.Id;
      console.log(obj);
      localStorage.setItem("studentTaskObj", JSON.stringify(obj));
      this.$router.push({
        path: "/h5/studentTask/detail",
        query: {
          userId: this.user.Id,
        },
      });
    },
    finishadd() {
      this.feedList = [];
      this.recPage = 1;
      this.postQueryAllTopicList();
    },
  },
};
</script>
<style lang="less" scoped>
.sliding_box {
  // background-color: #3a78f9;
  background-color: #fff;
  padding: 20px 10px;
  margin-bottom: 10px;
  ul {
    display: flex;
    li {
      margin-right: 10px;
      display: inline-block;
      min-width: 115px;
      height: 24px;
      line-height: 24px;
      padding: 0 10px;
      border-radius: 20px;
      flex-shrink: 0;
      // color: #fff;
      color: #afafaf;
    }
    .active {
      background: #ffffff;
      // color: #3a78f9;
      color: #333;
    }
  }
}
.van-cell {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  .custom-title {
    color: #333333;
    font-size: 15px;
    font-weight: bold;
  }
  .custom-right {
    width: 62px;
    height: 24px;
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    // background-color: #3a78f9;
    background-color: #333;
    border-radius: 30px;
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
